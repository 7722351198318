import React, { Fragment } from 'react';
import style from './post-create.module.scss';
import shareStyle from './post-detail-share.module.scss';
import { Container } from './container';
import { CloseButton } from './closeButton';
import StyledScrollbars from './styled-scrollbars';
import postStyle from '../components/post-tile.module.scss';
import { MapModule } from '../modules/post/map';
import { CommentSection } from './comment-section';
import { Button } from './button';
import { Spinner } from './spinner';
import { BlameIcon } from './icons/blame-icon';

const API_ENDPOINT = process.env.GATSBY_API_URL;

export const PostDetail = ({
  onClose,
  onSend,
  onLike,
  onBlame,
  category,
  comments,
  content,
  coordinates,
  created,
  image,
  blamed,
  liked,
  likes,
  own,
  slug,
  tags,
  title,
  user,
  variant,
  visible,
  categoryObj,
  alias,
  map,
  modifier,
  isSending = false,
  errors = []
}) => {
  const isPreview = modifier === 'preview';

  return (
    <>
      <div className={style.postWrapper} style={{ textAlign: 'initial' }}>
        <div className={style.post}>
          <div className={style.closeRow}>
            <Container modifier="contentWidthOverlay">
              <CloseButton onClick={onClose} />
            </Container>
          </div>
          <div style={{ height: 'calc(100% - 65px)' }}>
            <StyledScrollbars>
              <Container modifier="contentWidthOverlay">
                <section
                  className={style.noBorder}
                  style={{
                    marginBottom: 50,
                    float: 'left',
                    width: '60%',
                    position: 'relative',
                    paddingLeft: '80px'
                  }}
                >
                  {Boolean(categoryObj) && (
                    <img
                      src={
                        categoryObj.image.file.startsWith('http')
                          ? categoryObj.image.file
                          : require('../images/' + categoryObj.image.file)
                      }
                      width="60"
                      height="60"
                      alt={categoryObj.title}
                      style={{ position: 'absolute', left: '0', top: '45px' }}
                    />
                  )}
                  <h2>{title}</h2>
                  <div style={{ marginBottom: '20px' }}>{content}</div>
                  <div style={{ marginBottom: '30px', color: 'rgb(204,204,204)' }}>
                    <b>Kategorie:</b> <span>{category}</span>
                  </div>
                  {!blamed && !isPreview && (
                    <span
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginLeft: '14px',
                        marginTop: '2px'
                      }}
                      onClick={onBlame}
                    >
                      <BlameIcon />
                    </span>
                  )}
                  <div style={{ color: '#ccc', fontWeight: '300', fontSize: '14px' }}>
                    {Boolean(slug) && (
                      <span style={{ float: 'left', marginRight: 30 }}>
                        <strong>Chiffre:</strong> {slug}
                      </span>
                    )}
                    {Boolean(alias) ? (
                      <span style={{ float: 'left' }}>
                        <strong>Alias:</strong> {alias}
                      </span>
                    ) : (
                      Boolean(user) && (
                        <span style={{ float: 'left' }}>
                          <strong>Benutzer:</strong> {user.username}
                        </span>
                      )
                    )}
                    {!isPreview && (
                      <>
                        <div className={shareStyle.button} style={{ float: 'right' }}>
                          <div className={shareStyle.popover} style={{ display: 'none' }}>
                            <a
                              href={`mailto:?&amp;subject=Perspektiven%20im%20♥%20von%20Bad%20Dürrheim&amp;body=Guck%20mal%20was%20ich%20entdeckt%20habe%3A%0A${encodeURI(
                                `${API_ENDPOINT}/${variant}/posts?slug=${slug}`
                              )}`}
                              className={shareStyle.link}
                            >
                              &nbsp;
                            </a>
                            <a
                              href={`https://twitter.com/home?status=${encodeURI(
                                `${API_ENDPOINT}/${variant}/posts?slug=${slug}`
                              )}`}
                              target="_blank"
                              className={shareStyle.link}
                              rel="noopener noreferrer"
                            >
                              &nbsp;
                            </a>
                            <a
                              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                                `${API_ENDPOINT}/${variant}/posts?slug=${slug}`
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={shareStyle.link}
                            >
                              &nbsp;
                            </a>
                          </div>
                        </div>
                        <span style={{ float: 'right' }}>
                          <span className={`${style.lastUpdated} ${postStyle.likeButton}`}>
                            <div className={postStyle.countBox}>{likes}</div>
                            <img
                              src={require(`../images/heart-${liked ? 'filled' : 'outline'}.png`)}
                              alt="like"
                              className="likeButton"
                              onClick={onLike}
                            />
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                </section>

                <section className={style.noBorder} style={{ float: 'right', width: '40%' }}>
                  {image !== null && typeof image !== 'undefined' ? (
                    <img
                      src={isPreview ? URL.createObjectURL(image) : `${API_ENDPOINT}${image}`}
                      style={{ width: '100%', paddingRight: 15 }}
                      alt="Beispiel Bild"
                    />
                  ) : (
                    <span>Es wurde kein Bild hochgeladen</span>
                  )}
                  <MapModule
                    required
                    mapStyle={map && map.style}
                    zoom={15}
                    minZoom={15}
                    maxZoom={15}
                    marker={coordinates}
                    markerIcon={Boolean(categoryObj.marker) ? categoryObj.marker.file : undefined}
                    center={{ lng: coordinates.longitude, lat: coordinates.latitude }}
                    mapOnly={true}
                  />
                </section>
              </Container>

              {isPreview ? (
                <Container
                  modifier="contentWidthOverlay"
                  style={{ textAlign: 'left', marginTop: 50, marginBottom: 40 }}
                >
                  <div style={{ color: 'red', marginBottom: 20 }}>
                    {errors.map((err, i) => {
                      return (
                        <Fragment key={i}>
                          <span>{err}</span>
                          <br />
                        </Fragment>
                      );
                    })}
                  </div>
                  <Button onClick={onClose} style={{ marginRight: 20, backgroundColor: 'gray' }}>
                    Bearbeiten / Ändern
                  </Button>
                  <Button onClick={onSend} modifier={isSending && 'loading'}>
                    {isSending && <Spinner />} Beitrag veröffentlichen
                  </Button>
                </Container>
              ) : (
                <CommentSection slug={slug} />
              )}
            </StyledScrollbars>
          </div>
        </div>
      </div>
    </>
  );
};
