import React, { Component } from 'react';
import postCreateStyle from '../../components/post-create.module.scss';
import style from './category.module.scss';
import MDReactComponent from 'markdown-react-js';
import { Info } from './info';

export class Category extends Component {
  clickHandler = (name, item) => {
    this.category = name;
    this.props.onChange({ category: name, selectedCategory: this.hovered, categoryObj: item });
  };

  mouseOverHandler = name => {
    this.hovered = name;
    this.props.onChange({ category: this.category, selectedCategory: name });
  };

  mouseLeave = () => {
    this.hovered = this.category;
    this.props.onChange({ category: this.category, selectedCategory: this.hovered });
  };

  render() {
    const { title, info, items } = this.props;
    let hoverSet = false;
    return (
      <>
        <h2>
          {!this.props.required && <span className={postCreateStyle.optionalTag}>optional</span>}
          {title}
        </h2>
        <div className={postCreateStyle.sectionLeft} style={{ marginBottom: -20 }}>
          {items.map(item => {
            const { name, image } = item;
            let classNames = style.categoryIcon;
            if (this.props.category === name) classNames += ' ' + style.selected;

            return (
              <div
                onMouseOver={() => this.mouseOverHandler(name)}
                onMouseLeave={this.mouseLeave}
                onClick={() => this.clickHandler(name, item)}
                key={`icon-${name}`}
                className={classNames}
                tabIndex="0"
              >
                <img
                  src={
                    image.file.startsWith('http')
                      ? image.file
                      : require('../../images/' + image.file)
                  }
                  alt={name}
                />
                <br />
                {name}
              </div>
            );
          })}
        </div>
        <div className={postCreateStyle.sectionRight}>
          {items.map(({ name, description }) => {
            const nodeStyle = { display: this.props.selected === name ? 'block' : 'none' };

            if (!this.props.selected && !hoverSet) {
              hoverSet = true;
              nodeStyle.display = true;
            }

            const compText = description.reduce((acc, { text }) => {
              if (typeof text === 'string') return (acc += `\n\n${text}`);
              return acc;
            }, '');

            return (
              <div
                key={`info-${name}`}
                ref={node => (this[name] = node)}
                style={nodeStyle}
                className={style.infoBox}
              >
                <h4>{name}</h4>
                <MDReactComponent text={compText} />
              </div>
            );
          })}
          <Info text={info} />
        </div>
      </>
    );
  }
}
