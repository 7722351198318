import React from 'react';
import { Link } from '@reach/router';
import style from '../components/tile.module.scss';
import { containsAll } from '../api/localstorage-provider';

const TileContent = ({
  image,
  title,
  colorCode,
  textHighlightColor,
  letter,
  description,
  lastUpdated
}) => (
  <>
    <div className={style.tileImage}>
      <figure>
        <img
          alt={title}
          src={
            image && image.file && image.file.substring(0, 4) === 'http'
              ? image.file
              : require(`../images/${image.file}`)
          }
        />
      </figure>
    </div>

    <div className={style.content}>
      {letter && (
        <span
          style={{
            backgroundColor: colorCode,
            color: textHighlightColor || '#000'
          }}
          className={style.category}
        >
          {letter}
        </span>
      )}
      <h2 className={style.title}>{title}</h2>

      <p className={style.description}>{description}</p>
    </div>

    <span className={style.lastUpdated}>Stand: {lastUpdated}</span>
  </>
);

export const Tile = ({ link, className, needs, showModal, ...rest }) => (
  <div className={className}>
    {!Boolean(needs) || (Array.isArray(needs) && containsAll('posted', needs)) ? (
      <Link to={`/${link}${Boolean(rest.skipContent) ? '/posts' : ''}`}>
        <TileContent {...rest}></TileContent>
      </Link>
    ) : (
      <div onClick={showModal}>
        <TileContent {...rest}></TileContent>
      </div>
    )}
  </div>
);
