import React, { useEffect } from 'react';
import { isFunction } from 'util';
import { Info } from './info';

import postCreateStyle from '../../components/post-create.module.scss';
import style from './select.module.scss';
import { get, add } from '../../api/localstorage-provider';

/**
 *
 * @param {object} props
 */
const City = props => {
  /**
   * @param {import('react').ChangeEvent<HTMLSelectElement>} event
   */
  const onChange = event => {
    const city = event.target.value;
    add('city', city);
    if (isFunction(props.onChange)) props.onChange(city);
  };

  useEffect(() => {
    onChange({ target: { value: get('city') || props.value } });
  }, []);

  const { title, info, value, options } = props;

  return (
    <>
      <div className={postCreateStyle.sectionLeft}>
        {title}
        <br />
        <div className={style.select}>
          <select name="birthyear" onChange={onChange} value={value}>
            {options.map(name => {
              return (
                <option key={name} value={name}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className={postCreateStyle.sectionRight}>{info.length > 0 && <Info text={info} />}</div>
    </>
  );
};

export { City };
