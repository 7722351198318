import React, { Component } from 'react';
import { Button } from './button';
import { Container } from './container';
import { getCommentsPost, commentPost, likeComment, blameComment } from '../api';
import SlideToggle from 'react-slide-toggle';
import { Comment } from './comment';
import Captcha from '../modules/post/captcha';
import { Spinner } from './spinner';

export class CommentSection extends Component {
  state = {
    comments: [],
    ownComment: '',
    email: 'email',
    emailIsValid: true,
    captchaInput: '',
    captchaCode: undefined,
    captchaError: false,
    isSending: false,
    openBlameDialogSlug: '',
    blameCaptchaInput: undefined,
    blameCaptchaCode: '',
    blameReason: '',
    blameEmailInput: '',
    blameCaptchaError: false,
    blameLoading: false
  };

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments() {
    getCommentsPost({ slug: this.props.slug }).then(([comments, err]) => {
      if (!err && Array.isArray(comments)) {
        this.setState({ comments });
      }
    });
  }

  updateOwnComment = e => {
    this.setState({ ownComment: e.target.value });
  };

  submitComment = e => {
    this.setState({ captchaError: false, isSending: true });

    if (this.state.captchaCode !== this.state.captchaInput) {
      this.setState({ captchaError: true, isSending: false });
      return;
    }

    commentPost({
      slug: this.props.slug,
      comment: this.state.ownComment,
      email: this.state.email
    }).then(([_, err]) => {
      if (!err) {
        this.setState({ ownComment: '', captchaInput: '', isSending: false });
        this.fetchComments();
      } else {
        console.error(err);
      }
    });
  };

  likeComment = slug => {
    likeComment({ slug }).then(([_, err]) => {
      if (!err) {
        this.fetchComments();
      } else {
        console.error(err);
      }
    });
  };

  blameComment = () => {
    if (this.state.blameCaptchaCode !== this.state.blameCaptchaInput) {
      return;
    }

    this.setState({ blameLoading: true });
    blameComment({
      slug: this.state.openBlameDialogSlug,
      reason: this.state.blameReason,
      email: this.state.blameEmailInput
    })
      .then(([result, error]) => {
        console.log(error);

        this.setState({
          openBlameDialogSlug: '',
          blameReason: '',
          blameCaptchaInput: '',
          blameEmailInput: '',
          blameLoading: false
        });
      })
      .catch(error => {
        console.error(error);
      });

    console.log('Reason:', this.state.blameReason);
    console.log('Email:', this.state.blameEmailInput);
    console.log('Captcha:', this.state.blameCaptchaInput, this.state.blameCaptchaCode);
  };

  onCaptchaInputChange = e => {
    this.setState({ captchaInput: e.target.value });
  };

  render() {
    return (
      <section
        style={{
          borderBottom: 'none',
          borderTop: '2px solid #c1c1c1',
          marginTop: '20px'
        }}
      >
        <Container modifier="contentWidthOverlay">
          <h2>Wie ist ihre Perspektive hierzu?</h2>

          <div style={{ padding: '0 80px' }}>
            <SlideToggle
              collapsed={true}
              duration={800}
              noDisplayStyle
              render={({ onToggle, setCollapsibleElement, toggleState, progress }) => (
                <div style={{ marginBottom: '20px' }}>
                  <Button onClick={onToggle} style={{ marginBottom: '20px' }}>
                    Kommentar schreiben
                  </Button>
                  <div style={{ overflow: 'hidden' }} ref={setCollapsibleElement}>
                    <textarea value={this.state.ownComment} onChange={this.updateOwnComment} />
                    <Captcha
                      length={4}
                      height="40"
                      width="150"
                      style={{ background: '#fff', position: 'relative', pointerEvents: 'none' }}
                      codes={[1, 2, 3, 4, 5, 6, 7, 8, 9, 0]}
                      onChange={code => {
                        this.setState({ captchaCode: String(code) });
                      }}
                    />
                    <input
                      type="text"
                      onChange={this.onCaptchaInputChange}
                      value={this.state.captchaInput}
                    ></input>
                    {this.state.captchaError && (
                      <span
                        style={{
                          display: 'block',
                          marginBottom: '15px',
                          color: 'red'
                        }}
                      >
                        Der eingegebene Captcha-Code stimmt nicht mit dem angezeigten Code überein.
                      </span>
                    )}
                    <Button
                      onClick={this.submitComment}
                      style={{ marginRight: '20px' }}
                      modifier={this.state.isSending && 'loading'}
                      disabled={this.state.isSending}
                    >
                      {this.state.isSending && <Spinner />}
                      Absenden
                    </Button>
                    <Button onClick={onToggle}>Abbrechen</Button>
                  </div>
                </div>
              )}
            />

            {this.state.comments.map(e => (
              <Comment
                key={e.slug}
                captchaError={this.state.blameCaptchaError}
                captchaInput={this.state.blameCaptchaInput}
                onCaptchaInputChange={evt => {
                  this.setState({
                    blameCaptchaInput: evt.target.value,
                    blameCaptchaError:
                      evt.target.value !== this.state.blameCaptchaCode &&
                      'Der eingegebene Captcha-Code stimmt nicht mit dem angezeigten Code überein.'
                  });
                }}
                onCaptchaCodeChange={code => {
                  this.setState({ blameCaptchaCode: code });
                }}
                emailInput={this.state.blameEmailInput}
                onEmailInputChange={evt => {
                  this.setState({ blameEmailInput: evt.target.value });
                }}
                blameDialogOpen={e.slug === this.state.openBlameDialogSlug}
                onLike={() => this.likeComment(e.slug)}
                onBlameInit={() => {
                  this.setState({
                    openBlameDialogSlug: e.slug,
                    blameReason: '',
                    blameCaptchaInput: '',
                    blameEmailInput: '',
                    blameLoading: false
                  });
                }}
                onBlameClose={() => {
                  this.setState({ openBlameDialogSlug: '' });
                }}
                blameReason={this.state.blameReason}
                onBlameReasonChange={evt => {
                  this.setState({ blameReason: evt.target.value });
                }}
                onSubmit={evt => {
                  evt.preventDefault();
                  this.blameComment();
                }}
                loading={this.state.blameLoading}
                {...e}
              />
            ))}
          </div>
        </Container>
      </section>
    );
  }
}
