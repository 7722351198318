import React, { Component } from 'react';
import Layout from '../components/layout';
import { Link } from '@reach/router';
import { Sidebar } from '../components/sidebar';
import { Arrow } from '../components/arrow';
import sidebarStyle from '../components/sidebar.module.scss';
import { CloseButton } from '../components/closeButton';
import { getPosts, likePost, blamePost } from '../api';
import { PostCreate } from '../components/post-create';
import { MapboxMap } from '../components/mapbox-map';
import { Tile } from '../components/post-tile';
import tileStyle from '../components/tile.module.scss';
import { PostDetail } from '../components/post-detail';
import { Footer } from '../components/footer';
import filterStyle from '../components/filter.module.scss';
import { graphql, StaticQuery, navigate } from 'gatsby';
import Captcha from '../modules/post/captcha';
import { Button } from '../components/button';
import withLocation from '../components/with-location';
import { validatePost, setPostVisibility } from '../api/post';
import WarningPost from '../components/warning-post';
import { containsAll, add, get } from '../api/localstorage-provider';
import PopupDialog from '../components/popup-dialog';
import { UserContext } from '../context/UserContext';

let Marker;
class PostList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      filters: Object.keys(props.pageContext.categories),
      error: undefined,
      openDetail: undefined,
      blamedPosts: new Set(),
      blamePost: undefined,
      initial: true,
      hasFilterBeenUsed: false,
      createOpen: false,
      isSafari: false
    };
  }

  componentDidMount() {
    const ReactMapboxGl = require('react-mapbox-gl');
    Marker = ReactMapboxGl.Marker;

    const posted = new Set(get('posted') || []);
    posted.add(this.props.pageContext.parent);
    add('posted', [...posted]);

    if (this.props.search.token !== undefined) {
      validatePost({ slug: this.props.search.slug, token: this.props.search.token }).then(() => {
        this.getPostList();
      });
    } else {
      this.getPostList();
    }

    this.setState({
      isSafari:
        navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0
    });
  }

  toggleFilter = categoryName => {
    if (this.state.hasFilterBeenUsed) {
      this.setState({
        filters: this.state.filters.includes(categoryName)
          ? this.state.filters.filter(e => e !== categoryName)
          : [...this.state.filters, categoryName]
      });
      return;
    }

    this.setState({ hasFilterBeenUsed: true, filters: [categoryName] });
  };

  getPostList = () => {
    getPosts({ variant: this.props.pageContext.parent }).then(([posts, error]) => {
      if (posts) {
        // posts.items = posts.items.slice(0, 10);
        this.setState({ posts: posts.items || [], error });
        if (this.state.openDetail) {
          this.setState({ openDetail: this.state.posts[this.state.openIndex] });
        }

        if (this.props.search.slug !== undefined && this.state.initial === true) {
          const post = posts.items.find(({ slug }) => slug === this.props.search.slug);

          if (Boolean(post)) {
            this.setState({ openDetail: post, initial: false });
          }
        }
      }
    });
  };

  hoverMarker = slug => event => {
    // TODO: move this into state instead of doing it by hand
    const element = document.getElementById(`list-${slug}`);
    element.style.outline = '3px solid #0095db';
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  hoverEndMarker = slug => event => {
    const element = document.getElementById(`list-${slug}`);
    element.style.outline = 'none';
  };

  openDetail = (item, index) => e => {
    if (e.target.classList && e.target.classList.contains('likeButton')) {
      this.likePost({ slug: item.slug });
    } else {
      this.setState({ openDetail: item });
      this.setState({ openIndex: index });
    }
  };

  likePost = ({ slug }) => {
    likePost({ slug: slug }).then(([post, error]) => {
      if (!error) {
        this.getPostList();
      }
    });
  };

  sendBlame = ({ slug }) => {
    blamePost({ slug: slug }).then(([post, error]) => {
      if (!error) {
        const blamedPosts = this.state.blamedPosts;
        blamedPosts.add(slug);
        this.setState({ blamedPosts: blamedPosts });
      }
    });
  };

  blamePost = ({ slug }) => {
    this.setState({ blamePost: slug });
  };

  submitBlame = event => {
    event.preventDefault();

    const captchaField = event.target.querySelector('[name="captcha"]');
    const reasonField = event.target.querySelector('[name="reason"]');
    const emailField = event.target.querySelector('[name="email"]');

    const formData = new FormData(event.target);

    if (!Boolean(formData.get('reason'))) {
      reasonField.classList.add('error');
      return;
    }

    if (this.state.captchaCode !== this.state.captchaInput) {
      captchaField.classList.add('error');
      return;
    }

    const sendData = {
      slug: this.state.blamePost,
      email: formData.get('email'),
      reason: formData.get('reason')
    };

    this.setState({ isBlaming: true });
    blamePost(sendData).then(([post, error]) => {
      if (!error) {
        captchaField.classList.remove('error');
        captchaField.value = '';
        reasonField.classList.remove('error');
        reasonField.value = '';
        emailField.value = '';

        const blamedPosts = this.state.blamedPosts;
        blamedPosts.add(this.state.blamePost);
        this.setState({ blamedPosts: blamedPosts, blamePost: undefined, isBlaming: undefined });
      }
    });
  };

  toggleVisibility = (item, user) => {
    if (user.loggedIn && user.roles.includes('ROLE_ADMIN')) {
      setPostVisibility({
        slug: item.slug,
        visible: !item.visible
      }).then(([post, error]) => {
        if (!error) {
          console.log(post);
          this.getPostList();
        }
      });
    }
  };

  isVisible = e => {
    const { categories } = this.props.pageContext;
    return categories[e.category] !== undefined && this.state.filters.includes(e.category);
  };

  onCaptchaInputChange = e => {
    this.setState({ captchaInput: e.target.value });
  };

  render() {
    const {
      colorCode,
      textHighlightColor,
      title,
      // quarterTitle,
      // letter,
      parent,
      previous,
      next,
      map,
      shape,
      categories,
      postCreateContent,
      needs
    } = this.props.pageContext;

    const markers = {};
    const noFilters = this.state.filters.length === 0;
    const showDialog = Array.isArray(needs) && needs.length > 0 && !containsAll('posted', needs);

    const mapModule = postCreateContent.find(function(el) {
      return el.type === 'module-post-map';
    });

    const { isSafari } = this.state;

    return (
      <Layout>
        <MapboxMap
          center={[map.lat, map.lng]}
          zoom={map.zoom}
          minZoom={map.minZoom}
          maxZoom={map.maxZoom}
          mapStyle={map.style}
          mapFeatures={shape}
        >
          {this.state.posts
            .filter(
              ({ coordinates, category }) =>
                !!coordinates &&
                coordinates.latitude &&
                coordinates.longitude &&
                categories[category] !== undefined &&
                this.state.filters.includes(category)
            )
            .map(item => {
              markers[item.slug] = (
                <Marker
                  anchor={'bottom'}
                  coordinates={{
                    lat: item.coordinates.latitude,
                    lng: item.coordinates.longitude
                  }}
                  key={`marker-${item.slug}`}
                  onClick={this.openDetail(item)}
                  style={{
                    cursor: 'pointer',
                    opacity: item.visible ? 1 : 0.5,
                    filter: isSafari ? 'none' : `grayscale(${item.visible ? 0 : 1})`
                  }}
                >
                  <img
                    src={require(`../images/${categories[item.category].marker.file}`)}
                    alt="Marker"
                    onMouseOver={this.hoverMarker(item.slug)}
                    onMouseOut={this.hoverEndMarker(item.slug)}
                  />
                </Marker>
              );

              return markers[item.slug];
            })}
        </MapboxMap>
        <Sidebar
          className={this.state.createOpen ? sidebarStyle.forceOverflow : undefined}
          render={currentSideBarState => {
            const baseTileStyle = `${tileStyle.tile} ${tileStyle[currentSideBarState]}`;
            return (
              <>
                <div className={sidebarStyle.buttonBar}>
                  {previous && (
                    <Link to={previous}>
                      <Arrow.Left aria-label="Vorherige Seite" />
                    </Link>
                  )}
                  {next && (
                    <Link to={next}>
                      <Arrow.Right aria-label="Nächste Seite" />
                    </Link>
                  )}
                  <Link to={parent} title="Zurück">
                    <CloseButton className={sidebarStyle.closePage} />
                  </Link>
                </div>
                <h2
                  style={{
                    backgroundColor: colorCode,
                    marginBottom: '22px',
                    padding: '1rem',
                    color: textHighlightColor || '#000'
                  }}
                >
                  {title} | <b>Beiträge erkunden</b>
                </h2>
                <CategoryFilter
                  noFilters={noFilters}
                  categories={categories}
                  filters={this.state.filters}
                  onCategoryToggle={this.toggleFilter}
                  colorCode={colorCode}
                  textHighlightColor={textHighlightColor}
                  posts={this.state.posts}
                />
                <StaticQuery
                  query={graphql`
                    query {
                      startPage: allDataJson(filter: { page: { eq: "start" } }) {
                        edges {
                          node {
                            content {
                              type
                              endDate
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={data => {
                    const countdown =
                      data &&
                      data.startPage &&
                      data.startPage.edges &&
                      data.startPage.edges[0] &&
                      data.startPage.edges[0].node &&
                      Array.isArray(data.startPage.edges[0].node.content) &&
                      data.startPage.edges[0].node.content.find(
                        el => el && el.type === 'module-countdown'
                      );

                    // Hint: change this to hide/show post create
                    const showPostCreate = Boolean(countdown.endDate)
                      ? new Date().getTime() <= new Date(countdown.endDate).getTime()
                      : true;

                    return (
                      <div className={tileStyle.tileWrapper}>
                        <UserContext.Consumer>
                          {user => {
                            const isAdmin = user.loggedIn && user.roles.includes('ROLE_ADMIN');

                            return [
                              showPostCreate && (
                                <PostCreate
                                  variant={parent}
                                  center={[map.lat, map.lng]}
                                  colorCode={colorCode}
                                  textHighlightColor={textHighlightColor}
                                  sidebar={currentSideBarState}
                                  key={'post-create-button'}
                                  shape={shape}
                                  onPostCreated={this.getPostList}
                                  content={postCreateContent}
                                  onToggleOpenState={open => this.setState({ createOpen: open })}
                                />
                              )
                            ]
                              .concat(
                                this.state.posts.filter(this.isVisible).map((item, index) => {
                                  const id = `list-${item.slug}`;

                                  return (
                                    <Tile
                                      key={id}
                                      id={id}
                                      post={item}
                                      className={
                                        baseTileStyle +
                                        (item.visible || isSafari ? '' : ` ${tileStyle.hidden}`)
                                      }
                                      onClick={this.openDetail(item, index)}
                                      category={categories[item.category]}
                                      like={() => this.likePost({ slug: item.slug })}
                                      toggleVisibility={() => this.toggleVisibility(item, user)}
                                      isAdmin={isAdmin}
                                    />
                                  );
                                })
                              )
                              .filter(Boolean);
                          }}
                        </UserContext.Consumer>
                      </div>
                    );
                  }}
                />
              </>
            );
          }}
        />
        {this.state.openDetail && categories[this.state.openDetail.category] !== undefined && (
          <PostDetail
            {...this.state.openDetail}
            categoryObj={categories[this.state.openDetail.category]}
            onClose={() => {
              this.setState({ openDetail: undefined });
            }}
            onLike={() => {
              this.likePost({ slug: this.state.openDetail.slug });
            }}
            onBlame={() => {
              this.blamePost({ slug: this.state.openDetail.slug });
            }}
            blamed={this.state.blamedPosts.has(this.state.openDetail.slug)}
            map={{
              style:
                (mapModule && mapModule.map && mapModule.map.style) ||
                'mapbox://styles/cheffen/cjyk5thfn07yu1dm0v66sfvbg'
            }}
          />
        )}
        {Boolean(this.state.blamePost) && (
          <PopupDialog
            onClose={() => this.setState({ blamePost: undefined, isBlaming: undefined })}
            image={require('../images/maennchen-warning.png')}
          >
            <h2>Diesen Beitrag Melden</h2>
            <form onSubmit={this.submitBlame}>
              <label>Warum melden?</label>
              <textarea name="reason" />
              <label>E-Mail-Adresse</label>
              <input type="text" name="email" />
              <Captcha
                length={4}
                height="40"
                width="150"
                style={{ background: '#fff', position: 'relative', pointerEvents: 'none' }}
                codes={[1, 2, 3, 4, 5, 6, 7, 8, 9, 0]}
                onChange={code => {
                  this.setState({ captchaCode: String(code) });
                }}
              />
              <input
                type="text"
                onChange={this.onCaptchaInputChange}
                value={this.state.captchaInput}
                name="captcha"
              />
              <Button modifier={this.state.isBlaming && 'loading'} disabled={this.state.isBlaming}>
                Absenden
              </Button>
            </form>
          </PopupDialog>
        )}
        {showDialog && (
          <WarningPost
            onClose={() => {
              return navigate('/map/district/allgemeines');
            }}
          />
        )}
        <Footer />
      </Layout>
    );
  }
}

function CategoryFilter({
  noFilters,
  colorCode,
  textHighlightColor,
  categories,
  posts,
  filters,
  onCategoryToggle
}) {
  return (
    <ul className={filterStyle.list}>
      {Object.keys(categories).map(categoryName => {
        const active = noFilters ? false : filters.includes(categoryName);

        return (
          <li
            key={categoryName}
            className={`${filterStyle.listItem}${active ? ` ${filterStyle.active}` : ''}`}
          >
            <button
              onClick={e => {
                e.preventDefault();
                onCategoryToggle(categoryName);
              }}
              style={{
                color: active ? textHighlightColor : colorCode || '#000',
                backgroundColor: active ? colorCode : 'white'
              }}
            >
              {categoryName} (
              {
                posts.filter(function(post) {
                  return post.category === categoryName;
                }).length
              }
              )
            </button>
          </li>
        );
      })}
    </ul>
  );
}

export default withLocation(PostList);
